document.addEventListener("turbolinks:load", () => {
  flatpickr(".flatpickr", {
    dateFormat: "d/m/Y"
  })

  flatpickr(".flatpickr-datetime", {
    enableTime: true,
    dateFormat: "d/m/Y h:i K"
  })

  flatpickr(".flatpickr-timeonly", {
    noCalendar: true,
    enableTime: true,
    dateFormat: 'h:i K'
  })

  $("[data-form-prepend]").click(function(e) {
    var obj = $($(this).attr("data-form-prepend"));
    obj.find("input, select, textarea").each(function() {
      $(this).attr("name", function() {
        return $(this)
          .attr("name")
          .replace("new_record", new Date().getTime());
      });
    });
    obj.insertBefore(this);
    return false;
  });

  if ($(".delete-question")) {
    $('body').on('click', '.delete-question', function(e) {
      $(this).closest('div.form-group').find('[type=checkbox]').prop( "checked", true );
      $(this).closest('div.event-question-form').hide();
      return false;
    });
  }

  if ($("#registration_nric")) {
    $("#generate-nric").click(function(e) {
      $("#registration_nric").val("000X");
      return false;
    });
  }

  if ($("#registration_mobile")) {
    $("#generate-mobile").click(function(e) {
      $("#registration_mobile").val(Math.random().toString().slice(2,10));
      return false;
    });
  }

  if ($("select#event_parent_id").length > 0) {
    parent_id_hide_inputs($("select#event_parent_id"));
    $("select#event_parent_id").change(function(e) {
      parent_id_hide_inputs($(this))
    });
  }

  function parent_id_hide_inputs($parent_id_field) {
    if($parent_id_field.val().length > 0){
      // hide
      $('#event_registration_start_datetime').closest('div.col-lg-6').hide()
      $('#event_registration_end_datetime').closest('div.col-lg-6').hide()
      $('#event_login_start_datetime').closest('div.col-lg-6').hide()
      $('#event_login_end_datetime').closest('div.col-lg-6').hide()
      $('#event_weblink_email_datetime').closest('div.col-lg-6').hide()
      $('#event_quiz_id').closest('div.form-group').hide()
    } else {
      // show
      $('#event_registration_start_datetime').closest('div.col-lg-6').show()
      $('#event_registration_end_datetime').closest('div.col-lg-6').show()
      $('#event_login_start_datetime').closest('div.col-lg-6').show()
      $('#event_login_end_datetime').closest('div.col-lg-6').show()
      $('#event_weblink_email_datetime').closest('div.col-lg-6').show()
      $('#event_quiz_id').closest('div.form-group').show()
    }
  }
})